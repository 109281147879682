<template>
  <el-row class="loginDiv" :style="`height: calc(100vh); width: calc(100vw);display:flex;`">
    <div style="width: calc(100vw - 530px); overflow: hidden" class="bgcBody">
      <img
        style="width: 100%; height: 100vh; margin-left: 128px; object-fit: contain"
        src="../../assets/login-bg2.png"
        alt=""
      />
      <!-- <img style="width: 100%;height: 100vh; object-fit: cover;" src="../../assets/蒙版组193x.png" alt=""> -->
    </div>
    <transition name="el-zoom-in-center">
      <!-- <div class="transition-box" style="margin: 0 auto; padding-top: 150px;"> -->
      <div
        class="transition-box"
        style="
          display: inline-block;
          float: right;
          height: 100%;
          background-color: white;
          padding-top: 100px;
          box-sizing: border-box;
          position: relative;
        "
      >
        <div class="login" style="color: #ffffff; text-align: center">
          <div style="font-size: 36px; font-weight: 700; margin: 100px 0; color: black">
            智装荟 SaaS 后台管理系统
          </div>

          <div
            style="
              width: 530px;
              color: #ffffff;
              margin: 0 auto;
              background-color: white;
              padding: 0px 100px;
              box-sizing: border-box;
            "
          >
            <!-- <img src="@/assets/logo.png" style="width: 153px; " /> -->
            <el-tabs v-model="activeName">
              <el-tab-pane label="用户登录" name="first">
                <el-form
                  :model="ruleForm"
                  status-icon
                  :rules="rules"
                  ref="ruleForm"
                  class="demo-ruleForm"
                  style="color: black"
                >
                  <el-form-item prop="username">
                    <el-input
                      prefix-icon="el-icon-user-solid"
                      placeholder="请输入用户名称"
                      v-model="ruleForm.username"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input
                      prefix-icon="el-icon-lock"
                      placeholder="请输入登录密码"
                      type="password"
                      v-model="ruleForm.password"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-row
                      style="
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        line-height: 0;
                        margin-bottom: 15px;
                      "
                    >
                      <el-col :span="12" style="text-align: left">
                        <el-checkbox v-model="checked"></el-checkbox>
                        <span style="padding-left: 8px; color: black">记住密码</span>
                      </el-col>
                      <el-col :span="12" style="text-align: right; cursor: pointer">
                        <a style="text-decoration: none; color: black" href="#/forget">忘记密码?</a>
                      </el-col>
                    </el-row>
                    <el-button :type="btn1()" style="width: 100%" @click="submitForm('ruleForm')">
                      登录
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <!-- <el-tab-pane label="短信登录" name="second">
                                <el-form
                                    :model="ruleForm"
                                    status-icon
                                    :rules="rules"
                                    ref="loginNew"
                                    class="demo-ruleForm"
                                    style="color: black"
                                >
                                    <el-form-item prop="phone">
                                        <div style="display: flex">
                                            <el-input
                                                prefix-icon="el-icon-mobile-phone"
                                                placeholder="请输入手机号"
                                                v-model="ruleForm.phone"
                                                autocomplete="off"
                                            ></el-input>
                                            <el-button
                                                type="info"
                                                plain
                                                :disabled="isTrue"
                                                @click="securityCode"
                                                style="width: 112px"
                                            >
                                                {{ isTime }}
                                            </el-button>
                                        </div>
                                    </el-form-item>
                                    <el-form-item prop="verify">
                                        <el-input
                                            prefix-icon="el-icon-edit-outline"
                                            placeholder="请输入验证码"
                                            v-model="ruleForm.verify"
                                            autocomplete="off"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-row
                                            style="
                                                font-size: 12px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-around;
                                                line-height: 0;
                                                margin-bottom: 15px;
                                            "
                                        >
                                            <el-col
                                                :span="24"
                                                style="text-align: right; cursor: pointer"
                                            >
                                                <a
                                                    style="text-decoration: none; color: black"
                                                    href="#/forget"
                                                >
                                                    忘记密码?
                                                </a>
                                            </el-col>
                                        </el-row>
                                        <el-button
                                            :type="btn2()"
                                            style="width: 100%"
                                            @click="submitForm('loginNew')"
                                        >
                                            登录
                                        </el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane label="扫描登录" name="third">角色管理</el-tab-pane> -->
            </el-tabs>
          </div>
        </div>
        <div
          style="
            font-size: 12px;
            color: #333333;
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 100%;
            text-align: center;
          "
        >
          Copyright 2017-2023 www.zhipeihui.cn | 智装荟saas后台管理系统 |
          <a target="_blank" href="https://beian.miit.gov.cn">沪ICP备2023039403号-1</a>
        </div>
      </div>
    </transition>
  </el-row>
</template>
<script>
import { CacheTool } from "@/security/security";
import { loginNew, send_smsNew } from '@/common/login';
export default {
  name: 'Login',
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录密码'));
      } else {
        if (this.ruleForm.password !== '') {
          // this.$refs.ruleForm.validateField('password');
        }
        callback();
      }
    };
    return {
      activeName: 'first',
      checked: false,
      ruleForm: {
        username: '',
        password: '',
        phone: '',
        verify: '',
      },
      rules: {
        username: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        verify: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      bgUrl: require('@/assets/mx-bg.jpg'),
      // 倒计时按钮
      isTime: '获取验证码',
      // 倒计时按钮禁用
      isTrue: false,
    };
  },
  created() {
    try {
      const cc = new CacheTool();
      const rs = cc.load_user_security();
      const arr = rs.split(" | ");
      if (arr.length == 2) { 
        const username = arr[0];
        const password = arr[1];
        if (username != "") { 
          if (password != "") { 
            this.ruleForm.password = password;
          }
          this.ruleForm.username = username;
          this.checked = true;
        }
      }
    } catch (ex) { console.log(ex) }
    // ~ 若存在原来存储的账号密码,但使用后删除
    if (!this.checked) { 
        if (window.localStorage.username) {
          this.ruleForm.username = window.localStorage.username;
          if (window.localStorage.password) {
            this.ruleForm.password = window.localStorage.password;
          }
          window.localStorage.removeItem("username");
          window.localStorage.removeItem("password");
        }
    }// ~ 使用后删除原始账号密码
  },
  methods: {
    btn1() {
      if (this.ruleForm.username != '' && this.ruleForm.password != '') {
        return 'primary';
      }
      return 'info';
    },
    btn2() {
      if (this.ruleForm.phone != '' && this.ruleForm.verify != '') {
        return 'primary';
      }
      return 'info';
    },
    // 返回倒计时
    secS() {
      let that = this;
      this.time = setInterval(() => {
        that.Countdown();
      }, 1000);
    },
    // 倒计时判断
    Countdown() {
      if (this.isTime > 0) {
        this.isTime = this.isTime < 11 ? `0${this.isTime - 1}` : this.isTime - 1;
      } else {
        this.isTrue = false;
        this.isTime = '获取验证码';
        clearInterval(this.time);
      }
    },
    // 获取当前手机号验证码
    securityCode() {
      send_smsNew({ mobile: this.ruleForm.phone }).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.isTrue = true;
          this.secS();
          this.isTime = 120;
          this.$message.success(msg);
        }
      });
    },
    submitForm(formName) {
      if (formName == 'loginNew') {
        this.$refs['loginNew'].validate((valid) => {
          if (valid) {
            let dt = {
              login_type: 'code',
              username: this.ruleForm.phone,
              password: this.ruleForm.verify,
            };
            loginNew(dt).then((res) => {
              let { code, msg } = res;
              if (code == 200) {
                this.$store.dispatch('user/setToken', res.result.token);
                sessionStorage.setItem('token', res.result.token);
                sessionStorage.setItem('name', res.result.username);
                this.$message.success(msg);
                window.location.reload();
              }
            });
          }
        });
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let dt = {
              login_type: 'password',
              username: this.ruleForm.username,
              password: this.ruleForm.password,
            };
            loginNew(dt).then((res) => {
              if (res.code == 200) {
                if (this.checked) {
                  try {
                    const cc = new CacheTool();
                    cc.save_user_security(
                      this.ruleForm.username,
                      this.ruleForm.password
                    );
                  } catch (ex) {
                    console.log(ex);
                  }
                } else {
                  window.localStorage.removeItem("user_hash");
                }
                this.$store.dispatch('user/setToken', res.result.token);
                sessionStorage.setItem('token', res.result.token);
                sessionStorage.setItem('name', res.result.username);
                this.$message({
                  type: 'success',
                  message: `${res.msg}`,
                  center: true,
                });
                window.location.reload();
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style>
.bgcBody {
  min-width: 300px;
  background-color: #174d74;
  /* background: url('../../assets/蒙版组183x.png'); */
  /* background: url('../../assets/蒙版组193x.png'); */
  /* background-size: 100% 100%; */
}

.login input {
  background: rgba(255, 255, 255, 0.2) !important;
  /* color: #FFFFFF!important; */
}

.login .el-input__icon {
  font-size: 18px;
  color: black;
  vertical-align: -2px !important;
}

.el-tabs__nav-wrap:after {
  background-color: white;
}

.el-tabs__item {
  padding: 0 30px;
}
</style>
